import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';

export default () => (
  <Layout>
    <SEO title="404" />
    <div className="container pb-24">
      <div className="flex flex-col-reverse md:flex-row items-stretch mb-24">
        <div className="md:w-1/2 md:pr-16 flex flex-col">
          <div className="text-primary flex-1">
            <h1 className="mb-6">404</h1>
            <p className="">Sorry that page could not be found.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
